import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import useI18n, { LocaleTypes } from '@i18n';
import routes from '@config/routes.json';
import { UserTypes } from '../../types.d';

// Helpers

interface IWidgetSignOptions {
    /** Роль, с которой будет зарегистрирован пользователь. Для кабинета вм всегда будет 'user' */
    role: UserTypes;
    /** false, если имеется собственный элемент, при клике на который будет открыт виджет */
    button: boolean;
    /** Язык, на котором будет отображаться виджет */
    lang: LocaleTypes;
    /** Нужна ли перезагрузка страницы после регистрации.
     * Тк мы не можем получить из виджета событие регистрации, всегда будет true
     * чтобы модалка закрывалась
     */
    needReload: boolean;
    /** Реферальная ссылка для указания click_id и inviter. */
    referal: string;
    /** URL для редиректа после авторизации. */
    redirectUrl: string;
}

interface IUseWidgetOptions {
    /** Отображать поле phone */
    needPhone: boolean;
    /** hideSocials = false скрыть блок с авторизацией через соц сети. */
    hideSocials: boolean;
}

interface IUseWidgetResult {
    /** Индикатор загрузки скрипта */
    readonly isLoading: boolean;
    /** Функция для отображения виджета */
    readonly showWidget: () => void;
}

declare global {
    interface Window {
        widgetSign: (
            id: string,
            options?: Partial<IWidgetSignOptions>,
        ) => { show: () => undefined };
    }
}

/**
 * Инициализирует виджет
 * @param id Id контейнера в который будет рендериться виджет.
 * При этом хук сам создаст контейнер для виджета.
 * Не нужно рендерить его в родительском компоненте.
 * @return {IUseWidgetResult}
 * */
const useWidget = (id: string, options?: IUseWidgetOptions): IUseWidgetResult => {
    const { locale } = useI18n();
    const [wasLoadedScript, setWasLoadedScript] = useState(false);
    const [widget, setWidget] = useState(null);

    /**
     * Инициализация скрипта
     */
    const createScriptLoader = () => {
        if (wasLoadedScript) return;
        // некстовый <script> не использует React для рендера
        // поэтому ref и функции внутри onLoad не работают
        // https://github.com/vercel/next.js/issues/20850#issuecomment-758014235
        const element = document.createElement('script');
        element.src = `${process.env.NEXT_PUBLIC_INSTANCE_PREFIX}/app-auth/widgets/signup/widget.js`;
        element.onload = () => {
            setWasLoadedScript(true);
        };
        element.onerror = (message, url, line, col, errorObj) => {
            setWasLoadedScript(true);
            Sentry.captureException(errorObj);
        };
        element.async = true;
        document.head.append(element);
    };

    /**
     * Создание обертки для виджета с переданным id
     */
    const createWrapperWidget = () => {
        const element = document.createElement('div');
        element.id = id;
        document.body.append(element);
    };

    /**
     * Создание виджета через глобальный метод, который устанавливает подключаемый скрипт
     */
    const createWidget = () => {
        if (!wasLoadedScript) return;
        if (!window.widgetSign) return;

        const widgetElement = window.widgetSign(`#${id}`, {
            role: UserTypes.user,
            button: false,
            lang: locale,
            needReload: true,
            redirectUrl: `${process.env.NEXT_PUBLIC_INSTANCE_PREFIX}/${locale}${routes.dashboard}`,
            ...options,
        });

        setWidget(widgetElement);
        widgetElement.show();
    };

    /**
     * Удаление виджета. Необходимо для корректного перевода при смене локали
     */
    const removeWidget = () => {
        const container = document.getElementById(id);
        if (container) container.remove();
        setWidget(null);
    };

    /**
     * Если виджет уже создан – показываем.
     * Если нет, то создаем.
     */
    const initWidget = async () => {
        await createScriptLoader();

        if (!widget) {
            createWrapperWidget();
            createWidget();
        } else {
            widget.show();
        }
    };

    useEffect(() => {
        const loadScript = async () => {
            await createScriptLoader();
        };

        if (id) loadScript();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        removeWidget();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale]);

    return {
        isLoading: id && !wasLoadedScript,
        showWidget: id ? initWidget : () => undefined,
    };
};

export default useWidget;
