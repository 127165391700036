import clsx from 'clsx';
import useI18n from '@i18n';
import { companyName } from '@config/globalDomain';
import { useBreakpoints, ScreenTypes } from '@hooks/useBreakpoints';

import NetworkCard from './NetworkCard';

import styles from './Network.module.scss';

import advertiserImg from './img/advertiser.png';
import epnImg from './img/epn.png';
import webmasterImg from './img/webmaster.png';

import advertiserImgMobile from './img/mobile/advertiser.png';
import epnImgMobile from './img/mobile/epn.png';
import webmasterImgMobile from './img/mobile/webmaster.png';

const IMAGES = {
    mobile: {
        advertiser: advertiserImgMobile,
        epn: epnImgMobile,
        webmaster: webmasterImgMobile,
    },
    desktop: {
        advertiser: advertiserImg,
        epn: epnImg,
        webmaster: webmasterImg,
    },
};

const mobileImagesCondition = [ScreenTypes.xs, ScreenTypes.sm];

const BlockNetwork: React.FC = () => {
    const { t } = useI18n();

    const breakpoint = useBreakpoints();

    const images = mobileImagesCondition.includes(breakpoint) ? IMAGES.mobile : IMAGES.desktop;

    return (
        <section className={styles.landingContainer}>
            <div className={styles.network}>
                <NetworkCard
                    imgSrc={images.advertiser}
                    title={t('Advertiser')}
                    text={t('Wants to increase their sales, places their offer on ePN Affiliate.', {
                        companyName,
                    })}
                />
                <div className={styles.network_arrow} />
                <NetworkCard
                    imgSrc={images.webmaster}
                    title={t('Webmaster')}
                    text={t(
                        'Attracts traffic to the advertisers site. When users make a purchase, the webmaster gets a reward',
                    )}
                />
                <div className={clsx(styles.network_arrow, styles.network_arrow__invert)} />
                <NetworkCard
                    imgSrc={images.epn}
                    title="ePN Affiliate"
                    text={t(
                        'Provides its platform, bringing advertisers and webmasters together. Monitors the quality of traffic and pays rewards',
                    )}
                />
            </div>
        </section>
    );
};

export default BlockNetwork;
