import { useRouter } from 'next/router';
import { GetStaticPaths, GetStaticProps } from 'next';

// Pages
import EpnHome from '@components/pages/home/epnbz';
import EpnCpaHome from '@components/pages/home/epncpa';

// Helpers
import useI18n, { getLocalePaths } from '@i18n';

import WithRoles, { rolesEnum } from '@HOCs/WithRole';
// Configs
import routes from '@config/routes.json';
import useRefreshToken from '@hooks/useRefreshToken';

import { CustomGetStaticPathsResult, CustomGetStaticPropsResult } from '../../types';

const IndexPageSwitcher: React.FC = () => {
    const isAuth = useRefreshToken();
    const router = useRouter();
    const { locale } = useI18n();

    if (isAuth) {
        router.push(`/${locale}${routes.dashboard}`);
    }

    if (process.env.NEXT_PUBLIC_PROJECT_NAME === 'epncpa') {
        return <EpnCpaHome />;
    }
    return <EpnHome />;
};

export const getStaticProps: GetStaticProps = async ({
    params,
}): Promise<CustomGetStaticPropsResult> => {
    return { props: { locale: params?.locale } };
};

export const getStaticPaths: GetStaticPaths = async (): Promise<CustomGetStaticPathsResult> => {
    return { paths: getLocalePaths(), fallback: false };
};

export default WithRoles(IndexPageSwitcher, [rolesEnum.guest]);
