import Head from 'next/head';

// Components
import BlockHeader from '@components/pages/home/epnbz/BlockHeader';
import BlockNetwork from '@components/pages/home/epnbz/BlockNetwork';
import BlockForWhom from '@components/pages/home/epnbz/BlockForWhom';
import BlockAdvantages from '@components/pages/home/epnbz/BlockAdvantages';
import BlockInstruments from '@components/pages/home/epnbz/BlockInstruments';
import BlockHowStartWork from '@pages/home/epnbz/HowStartWork';
// import BlockTheyTalkAboutUs from '@pages/home/BlockTheyTalkAboutUs';
import BlockOurPartners from '@components/pages/home/epnbz/BlockOurPartners';
import BlockStats from '@components/pages/home/epnbz/BlockStats';
import BlockSeo from '@components/pages/home/epnbz/BlockSeo';

// Helpers
import useI18n from '@i18n';
import getReferralParams from '@utils/urls/getReferralParams';
import { generateRoute } from '@utils/router';

import auth from '@config/auth.json';

import styles from '@pages/home/epnbz/about.module.scss';
import { companyName } from '@config/globalDomain';
import useRefreshToken from '@hooks/useRefreshToken';
import useWidget from '@hooks/useWidget';

const WIDGET_ID = 'registration-widget';

const IndexPage: React.FC = () => {
    const { t, locale } = useI18n();
    const refParams = getReferralParams();
    const isAuth = useRefreshToken();

    const { isLoading: widgetIsLoading, showWidget } = useWidget(WIDGET_ID);
    const registrationLink = generateRoute(auth.REG_URL, { locale });

    const redirect = () => {
        window.open(registrationLink);
    };

    const handleRegistration = refParams ? showWidget : redirect;
    // const isEnglishLanding = locale === LocaleTypes.en;

    const btnDisabledByWidget = !isAuth && widgetIsLoading;
    const domainURL = process.env.NEXT_PUBLIC_INSTANCE_PREFIX;

    return (
        <>
            <Head>
                <title>{t('ePN monetization is profit from your content', { companyName })}</title>
                <meta
                    name="description"
                    content={t('Monetization is earning through various models', { companyName })}
                />
                <meta
                    property="og:title"
                    content={t('Monetize content with ePN!', { companyName })}
                />
                <meta
                    property="og:description"
                    content={t('Convert your traffic into profit', { companyName })}
                />
                <meta property="og:image" content={`${domainURL}/wm/og_cover.png`} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={domainURL} />
            </Head>
            <main className="pb-[140px]">
                <section className={styles.landingContainer}>
                    <BlockHeader
                        handleRegistration={handleRegistration}
                        widgetIsLoading={btnDisabledByWidget}
                        isAuth={isAuth}
                    />
                    <BlockStats />
                </section>
                <BlockNetwork />
                <BlockForWhom />
                {isAuth && <BlockOurPartners />}
                <BlockAdvantages />
                <BlockInstruments />
                <BlockHowStartWork
                    handleRegistration={handleRegistration}
                    widgetIsLoading={btnDisabledByWidget}
                    isAuth={isAuth}
                />
                {/* {!isEnglishLanding ? (
                    <BlockTheyTalkAboutUs />
                ) : (
                    <div className="mt-[165px] lg:mt-[200px]" />
                )} */}
                <BlockSeo locale={locale} />
            </main>
        </>
    );
};

export default IndexPage;
